import React from "react"
import "../static/style/componentStyle/circle-btn.scss"
import { Link } from "gatsby"
import downloadFile from '../static/pdfs/LUMIQ_Data_Sheet.pdf'

const CircleBtn = props => {
  return (
    <>
      {props?.pdf ? (
        <a href={downloadFile} download={`LUMIQ_FactSheet.pdf`} name="dataSheet">
          <div className="platform-tour">
            <div className="platform-box">
              <div className="hover-button btn rounded">
                <p className="btn-text text-black tw-pl-24">{props.BtnText}</p>
              </div>
            </div>
          </div>
          </a>
      ) : (
        <Link to={`${props?.url}`} name="get-startd">
          <div className="platform-tour">
            <div className="platform-box">
              <div className="hover-button btn rounded">
                <p className="btn-text text-black tw-pl-24">{props.BtnText}</p>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  )
}

export default CircleBtn
